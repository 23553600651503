// Override Bootstrap's Sass default variables

// Toggle global options
$enable-shadows: true;

// Customize some defaults
$primary: #3C8CA5;
$green: #61AB43;
$border-radius: .4rem;
$min-contrast-ratio: 3;
$font-family-base: "SourceSansPro", system-ui, -apple-system, sans-serif;

// Import Bootstrap's CSS
@import 'common-imports';

// Custom styles
@import 'shared-custom-styles';
